<template>
  <v-container>
    <v-row justify="center" class="mb-6">
    <v-col cols="12" class="text-center">
      <h2 class="section-title">
        Bienestar y tranquilidad médica para ti y los tuyos
      </h2>
    </v-col>
  </v-row>
    <v-row justify="center">
      <v-col
      v-for="item in items"
        :key="item.src"
        cols="6"
        sm="6"
        md="3"
        class="mb-4 text-center"
      >
        <div class="text-center mb-2">
          <!-- <v-icon size="34px" class="mb-1">mdi-marker-check</v-icon> -->
          <div class="text-subtitle-1 font-weight-medium">{{ item.nombre }}</div>
        </div>
        <v-img
          class="mx-auto bounce-enter-active"
          :src="item.src"
          height="126px"
          :width="imgCobertura.width"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SeccionCobComponent',
  data() {
    return {
      items: [
        { src: `${process.env.VUE_APP_BASE_IMG}/coberturas/urgencia.png`, nombre: 'Urgencias' },
        { src: `${process.env.VUE_APP_BASE_IMG}/coberturas/consulta.png`, nombre: 'Consultas' },
        { src: `${process.env.VUE_APP_BASE_IMG}/coberturas/dental.png`, nombre: 'Dental' },
        { src: `${process.env.VUE_APP_BASE_IMG}/coberturas/visual.png`, nombre: 'Visual' }
      ]
    };
  },
  computed: {
    imgCobertura() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return { width: '100px' };
      case 'sm':
        return { width: '120px' };
      case 'md':
        return { width: '160px' };
      case 'lg':
        return { width: '200px' };
      case 'xl':
        return { width: '240px' };
      default:
        return { width: '120px' };
    }
  }
  }
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
  
}
</style>
