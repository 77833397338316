<template>
  <v-app>
    <SnackbarGlobal v-if="this.$route.meta.layout"/>
    <HeaderA v-if="this.$route.meta.layout"></HeaderA>
    <Header v-else></Header>
    <v-main v-if="!this.$route.meta.layout">
      <router-view />
      <!-- Botón "Ir arriba" que aparece al desplazarse -->
      <v-btn v-show="showScroll" fab dark color="azul_med" class="scroll-to-top" @click="scrollToTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-main>
    <FooterA v-if="this.$route.meta.layout"></FooterA>
    <Footer v-else></Footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['isLogged']),
    NumCarritoFromLocalStorage() {
      return localStorage.getItem('NumCarrito') || 0;
    },
    ItemsCarritoFromLocalStorage() {
      return JSON.parse(localStorage.getItem('ItemsCarrito') || '[]');
    }
  },
  mounted() {
    // Escuchar el evento de scroll cuando el componente está montado
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // Eliminar el listener del evento de scroll cuando se destruya el componente
    window.removeEventListener('scroll', this.handleScroll);
  },
  data: () => ({
    showScroll: false, // Controla la visibilidad del botón
    maxDate: null,
    activePicker: 'YEAR',
    date: null,
    menu: false,
    IdUser: null,
    valid: false
  }),
  components: {
    Header: () => import('@/components/Header.vue'),
    Footer: () => import('@/components/Footer.vue'),
    FooterA: () => import('@/components/layout/Footer.vue'),
    HeaderA: () => import('@/components/layout/Header.vue'),
    SnackbarGlobal: () => import('@/components/SnackbarGlobal.vue'),
  },
  methods: {
    goToSection(value) {
      const top = this.$refs[(value)].offsetTop;
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Desplazamiento suave
      });
    },
    handleScroll() {
      // Mostrar el botón solo cuando el usuario se ha desplazado hacia abajo
      this.showScroll = window.scrollY > 300;
    }
  }
};
</script>
<style lang="scss">
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000; /* Asegura que el botón esté por encima del contenido */
}
.html {
  font-family: "Open Sans", sans-serif;
}
/* Estilo base para pantallas grandes (mayores a 1024px) */
.responsive-text-h1 {
  font-size: 32px;
  font-family: "Open Sans", sans-serif;
}
.section-title {
  font-size: 2.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #616161;
  text-align: center;
}

/* Para tabletas (≤1024px) */
@media only screen and (max-width: 1024px) {
  .responsive-text-h1 {
    font-size: 24px;
  }
  .section-title {
    font-size: 2.2rem;
  }
}

/* Para celulares medianos (≤600px) */
@media only screen and (max-width: 600px) {
  .responsive-text-h1 {
    font-size: 20px;
  }
  .section-title {
    font-size: 1.8rem;
  }
}

/* Para celulares pequeños (≤480px) */
@media only screen and (max-width: 480px) {
  .responsive-text-h1 {
    font-size: 16px;
  }
  .section-title {
    font-size: 1.5rem;
  }
}

.estatus-expirado {
  font-weight: bold;
  color: #838588;
}

.estatus-activo {
  font-weight: bold;
  color: #006400;
}

.estatus-impago {
  font-weight: bold;
  color: #deb0b0;
}

.estatus-emitido {
  font-weight: bold;
  color: #800080;
}

.estatus-retardo {
  font-weight: bold;
  color: #d41565;
}

.edad {
  font-weight: bold;
  color: #002b64;
}

.cont-verde {
  border: 1px solid #12a14b;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  padding: 10px;
}

.cont-gris {
  border: 1px solid #cccccc;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  padding: 10px;
}

.cont-azul {
  border: 1px solid #6598C1;
  padding: 1%;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  // padding: 10px;
}

.mayuscula input {
  text-transform: uppercase;
}

.tarjeta {
  border: 1px solid #F5F5F5;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  // width: 340px;
  // height: 180px;
  background-color: #F5F5F5;
  padding: 3%;
  color: #494949;
}

.upper-input input {
  text-transform: uppercase
}

.fond-gris {
  border: 1px solid rgb(202, 211, 211);
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  background-color: rgb(228, 236, 236);
}

.cont-bord-gris {
  border: 1px solid #818181;
  padding: 1%;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  // padding: 10px;
}

.bordered-row {
  border: 2px solid #818181;
  /* Color del borde */
  border-radius: 16px;
  /* Bordes redondeados (opcional) */
  padding: 16px;
  /* Espaciado interno (opcional) */
}

.urgencias0 {
  color: #c28d8d;
}

.small-chip {
  font-size: 10px;
  color: #575757;
}

.card {
  width: 140px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.card img {
  width: 100%;
  height: auto;
}

.card-header {
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  background-color: #9cbcd7;
}

.card-body {
  padding: 8px;
  font-size: 10px;
  font-weight: bold;
  color: #545556;
}

.card-footer {
  padding: 16px;
  text-align: right;
  background-color: #f5f5f5;
}

.card-footer button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.card-footer button:hover {
  background-color: #0056b3;
}
</style>
