<template>
    <v-container class="faq-container py-9" fluid>
      <v-row justify="center">
        <v-col cols="12" md="8" class="text-center">
          <h2 class="font-weight-bold mb-10 text-h4 faq-title">
            Preguntas Comunes
          </h2>
          <v-expansion-panels
            multiple
            elevation="2"
            class="faq-panels"
          >
            <v-expansion-panel
              v-for="(faq, index) in faqs"
              :key="index"
            >
              <v-expansion-panel-header class="justify-center">
                <v-icon class="mr-2" color="primary">mdi-help-circle</v-icon>
                <span>{{ faq.pregunta }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                {{ faq.respuesta }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'FaqSection',
    data() {
      return {
        faqs: [
          {
            pregunta: '¿Si tengo una urgencia, ¿qué cubre Medicover?',
            respuesta: 'Medicover cubre accidentes o emergencias medicas en las cuales su estancia hospitalaria no sea mayor a 24horas',
          },
          {
            pregunta: '¿Qué me cubre Medicover en mi cobertura dental?',
            respuesta: 'Al acudir con odontólogos de la red, tu consulta, valoración y profilaxis no tienen costo. El especialista realizará la valoración y solicitará la autorización correspondiente según el tratamiento que necesites. Solo espera la confirmación de Medicover para comenzar tu tratamiento.(Consulta los procedimientos cubiertos directamente con tu agente.)',
          },
          {
            pregunta: '¿Qué me cubre Medicover en mi cobertura visual?',
            respuesta: 'Te apoyamos con un monto para la compra de tus lentes de graduación. Consulta el monto vigente en tu contrato o con tu agente. Aplica copago.',
          },
          {
            pregunta: '¿Que no cubre Medicover en mi cobertura de urgencias médicas?',
            respuesta: 'Antes que nada, te compartimos cómo saber cuándo acudir por una emergencia médica: Toda alteración súbita de un órgano o función que ponga en peligro la vida, la integridad corporal o la viabilidad de alguno de los órganos, y que requiera atención médico-quirúrgica.Por lo tanto, no se cubrirán consultas médicas donde no haya habido un procedimiento quirúrgico ni se haya suministrado tratamiento médico. En cuanto a los estudios radiológicos, quedan excluidas las tomografías y resonancias. También te recomendamos leer los términos y condiciones en tu contrato.'
          },
          {
            pregunta: '¿Qué puedo hacer si no hay doctores disponibles en la especialidad que necesito?',
            respuesta: 'Si en ese momento no hay médicos disponibles en la especialidad que necesitas y esa especialidad se encuentra dentro de las especialidades Medicover, se gestionará un reembolso. Consulta con tu agente para conocer el proceso y los requisitos.',
          },
          {
            pregunta: '¿En caso de acudir a urgencias, ¿qué tipo de médico debe atenderme para que esté cubierta por Medicover?',
            respuesta: 'Todos los medicos de guardia o staf propios del hospital pueden atenderte.',
          },
          {
            pregunta: '¿Dónde puedo obtener mis pases médicos?',
            respuesta: 'En tu perfil de Medicover, disponible en https://mi.medicover.mx/user/micuenta, puedes consultar tus pases médicos, generar nuevos, revisar tu estatus, acceder a tus facturas y mucho más.',
          },
          {
            pregunta: '¿Dónde puedo descargar mis facturas?',
            respuesta: 'A través de tu perfil https://mi.medicover.mx/user/micuenta, tendrás la opción de descargar tus facturas en formato PDF y XML. Para completar la solicitud de facturación, por favor envía tu constancia fiscal, correo electrónico y número de teléfono.',
          }
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .faq-container {
    background: #f9f9fb;
    border-radius: 16px;
    min-height: 60vh;
    display: flex;
    align-items: center;
  }
  
  .faq-title {
    color: #0a3d85;
  }
  
  .faq-panels {
    background-color: #fff;
    border-radius: 12px;
    padding: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  }
  
  .faq-panels .v-expansion-panel-header {
    font-weight: 600;
    font-size: 16px;
    color: #333;
  }
  
  .faq-panels .v-expansion-panel-content {
    font-size: 15px;
    line-height: 1.7;
    color: #555;
    padding: 12px 20px;
  }
  </style>
  