<template>
  <div>
  <seccion-slider/>
  <seccion-cob/>
  <seccion-planes/>
  <preguntas-respuestas></preguntas-respuestas>
  <nosotros></nosotros>
  </div>
</template>

<script>
  import SeccionSlider from '../components/home/Slider'
  import SeccionCob from '../components/home/Coberturas'
  import PreguntasRespuestas from '../components/home/FaqSection'
  import SeccionPlanes from '../components/home/Planes'
  import Nosotros from '../components/home/Nosotros'
  export default {
    name: 'Home',

    components: {
      SeccionSlider,
      SeccionCob,
      PreguntasRespuestas,
      SeccionPlanes,
      Nosotros
    },
  }
</script>
