<template>
  <v-container fluid>
    <v-parallax :style="{ height: Parallax.height }" dark src="https://medicover.mx/img/fondo-doctor.png" contain>

      <div class="section-title mt-6 mb-6">Elige el plan perfecto para cada etapa de la vida</div>
      <v-spacer></v-spacer>
      <div class="plans-container">
      <v-row dense justify="center">
        <v-col v-for="card in cards" :key="card.title" cols="12" sm="6" md="4" lg="2" class="d-flex">
          <v-card class="plan-card elevation-3 rounded-lg" outlined>
            <v-card-title class="text-uppercase font-weight-bold text-h6"
              :class="['grey--text', 'text-center', 'plan-card-title']">
              {{ card.title }}  
            </v-card-title>
            <v-card-subtitle class="grey--text px-4 py-2" style="font-size: 14px; line-height: 1.6;">
              <div v-for="(beneficio, index) in beneficios[card.title]" :key="index" class="d-flex align-center mb-1">
                <v-icon size="20" class="mr-2 green--text">mdi-check</v-icon>
                <span class="beneficio-text">{{ beneficio }}</span>
              </div>
            </v-card-subtitle>

            <v-card-actions class="d-flex flex-column justify-end align-center text-center py-4 grey--text">
              <v-spacer></v-spacer>
              <div class="font-weight-bold text-h5 ">
                Desde
              </div>
              <div class="font-weight-bold text-h4">
                {{$formatCurrency (card.costo || 0)}} mensuales
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      </div>
    </v-parallax>
  </v-container>
</template>

<script>
export default {
  name: 'SeccionPlanesComponent',
  data() {
    return {
      cards: [
        { id: '1', title: 'Plus Individual', costo: 657 },
        { id: '2', title: 'Plus Individual 50', costo: 1161},
        { id: '3', title: 'Plus Familiar', costo: 1364 },

        { id: '4', title: 'La Bene Individual 50', costo: 1039 },
        { id: '5', title: 'La Bene Familiar', costo: 1176 }

      ],
      beneficios: {
        'Plus Familiar': [
          'Cobertura de urgencias en red hospitalaria con monto de urgencias $60,000.00 MX',
          'Protege a tus familiares directos como beneficiarios',
          'Cobertura de consultas médica con especialistas de nuestra red (6 LUC)',
          'Cobertura dental opcional, agrégala si lo prefieres',
          'Cobertura visual opcional, agrégala si lo prefieres',
        ],
        'Plus Individual': [
          'Cobertura de urgencias en red hospitalaria con monto de urgencias $60,000.00 MX',
          'Cobertura de consultas médica con especialistas de nuestra red (6 LUC)',
          'Puedes agregar cobertura Dental',
          'Puedes agregar cobertura Visual',
        ],
        'Plus Individual 50': [
          'Especializado para mayores de 50 años',
          'Cobertura de urgencias en red hospitalaria con monto de urgencias $60,000.00 MX',
          'Cobertura de consultas médica con especialistas de nuestra red (6 LUC)',
          'Puedes agregar cobertura Dental',
          'Puedes agregar cobertura Visual',
        ],
        'La Bene Familiar': [
          'Cobertura de urgencias en hospital LA BENEFICENCIA ESPAÑOLA con monto de urgencias $60,000.00 MX',
          'Protege a tus familiares directos como beneficiarios',
          'Cobertura de consultas médica con especialistas de nuestra red (6 LUC)',
          'Puedes agregar cobertura Dental',
          'Puedes agregar cobertura Visual',
        ],
        'La Bene Individual 50': [
          'Especializado para mayores de 50 años',
          'Cobertura de urgencias en hospital LA BENEFICENCIA ESPAÑOLA con monto de urgencias $60,000.00 MX',
          'Cobertura de consultas médica con especialistas de nuestra red (6 LUC)',
          'Puedes agregar cobertura Dental',
          'Puedes agregar cobertura Visual',
        ],
      },
    };
  },
  methods: {
    agregarCarrito(id) {
      console.log(id);
      localStorage.setItem('NumCarrito', 1);
    },
  },
  computed: {
    Parallax() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return {
            height: '1000px',
          };
        case 'sm':
          return {
            height: '1000px',
          };
        case 'md': // Si es una pantalla mediana
          return {
            height: '900px',
          };
        case 'lg': // Para pantallas grandes, como las de escritorio
          return {
            height: '1000px',
          };
        case 'xl': // Para pantallas extra grandes
          return {
            height: '1200px', // Lo hacemos más grande
          };
        default:
          return {
            height: '700px',
          };
      }
    },
  },
};
</script>

<style scoped>
.plans-container {
  max-height: 80vh; /* Limita la altura para que no ocupe toda la pantalla en dispositivos pequeños */
  overflow-y: auto; /* Permite desplazamiento vertical si las tarjetas no caben */
  padding-right: 10px; /* Espacio extra para permitir scroll sin cortarse */
}

/* Contenedor con desplazamiento horizontal en móviles */
@media (max-width: 600px) {
  .plans-container {
    max-height: 80vh; /* Aumenta el tamaño en pantallas más pequeñas */
    overflow-y: auto; /* Permite desplazamiento vertical */
    padding-right: 10px; /* Asegura que el scroll no se corte */
  }

  .v-row {
    display: block; /* Hace que las tarjetas se alineen una debajo de la otra */
  }

  .v-col {
    margin-bottom: 16px; /* Espacio entre las tarjetas */
    width: 100%; /* Hacer que las tarjetas ocupen todo el ancho */
  }

  .plan-card {
    min-height: 250px; /* Tamaño fijo para las tarjetas */
  }
}

.plan-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.plan-card-title {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.v-card-subtitle {
  color: #dcdcdc;
  font-size: 13px;
  line-height: 1.7;
}

.v-icon {
  transition: color 0.3s ease;
}

.v-icon:hover {
  color: #f2a900;
}

.beneficio-text {
  font-weight: bold;
  color: #0a3d85;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
}

.beneficio-text::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f2a900;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.beneficio-text:hover {
  color: #f2a900;
  transform: scale(1.05);
}

.beneficio-text:hover::after {
  opacity: 1;
}
</style>
